<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col
        lg="8"
        md="6"
        class="d-none d-lg-flex align-items-center p-0"
      >
        <splash-screen />
      </b-col>
      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('login.reset_password') }}
            <feather-icon
              icon="LockIcon"
              size="18"
            />
          </b-card-title>

          <p>{{ $t('login.reset_password_notice') }}</p>
          <!-- form -->
          <validation-observer
            #default="{ invalid, validate }"
            ref="loginValidation"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="validate().then(submitCredentials)"
            >
              <!-- new password -->
              <validation-provider #default="{ errors }" :name="$t('login.new_password')" class="validation-required" rules="required">
                <b-form-group :label="$t('login.new_password')">
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    class="input-group-merge"
                  >
                    <b-form-input
                      id="reset-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility()" />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <validation-provider #default="{ errors }" :name="$t('login.confirm_password')" class="validation-required" rules="required">
                <b-form-group :label="$t('login.confirm_password')">
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    class="input-group-merge"
                  >
                    <b-form-input
                      id="reset-passwordConf"
                      v-model="passwordConfirmation"
                      :state="errors.length > 0 ? false : null"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility()" />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                {{ $t('login.set_new_password') }}
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <div>
              <b-link :to="{name : 'login'}">
                <feather-icon icon="ArrowLeftIcon" />
                <span> {{ $t('login.back_login') }}</span>
              </b-link>
            </div>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapState, mapActions } from 'vuex'
import splashScreen from '@/views/base/SplashScreen.vue'
import { patchResetPasswordRequest } from '@/request/authApi/requests/apiRequests'

export default {
  name: 'ResetPassword',
  components: {
    splashScreen,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      sideImg: require('@/assets/images/pages/login/airplane.jpg'),
      logoFull: require('@/assets/images/logo/logo.svg'),
    }
  },
  computed: {
    ...mapState('appConfig', ['layout']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (this.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login/airplane-dark.jpg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapActions('auth', ['login']),

    submitCredentials() {
      const resetPasswordObj = {
        passwordResetToken: this.$route.query.token,
        newPassword: this.password,
        repeatNewPassword: this.passwordConfirmation,
      }
      patchResetPasswordRequest(resetPasswordObj)
        .then(result => {
          if (result.error) {
            this.toaster(result.error.message, 'danger', 'AlertTriangleIcon')
          } else {
            this.$router.push({ name: 'reset-password-validation' })
          }
        })
        .catch(() => false)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
