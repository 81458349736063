var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-0",attrs:{"lg":"8","md":"6"}},[_c('splash-screen')],1),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t('login.reset_password'))+" "),_c('feather-icon',{attrs:{"icon":"LockIcon","size":"18"}})],1),_c('p',[_vm._v(_vm._s(_vm.$t('login.reset_password_notice')))]),_c('validation-observer',{ref:"loginValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validate = ref.validate;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();validate().then(_vm.submitCredentials)}}},[_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('login.new_password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('login.new_password')}},[_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":function($event){return _vm.togglePasswordVisibility()}}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{staticClass:"validation-required",attrs:{"name":_vm.$t('login.confirm_password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('login.confirm_password')}},[_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-passwordConf","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"placeholder":"············"},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":function($event){return _vm.togglePasswordVisibility()}}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('login.set_new_password'))+" ")])],1)]}}])}),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('div',[_c('b-link',{attrs:{"to":{name : 'login'}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('login.back_login')))])],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }